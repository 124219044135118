export const filterPaywallPrice = (paywallPrice: any) => {
  if (!paywallPrice) {
    return {
      id: null,
    };
  }

  // Do not add label strings here, we won't be able to fetch the correct locale
  return {
    id: paywallPrice.id,
    scenario: paywallPrice.scenario,
    amount: paywallPrice.amount,
    upfront_payment_amount: paywallPrice.upfront_payment_amount,
    installments_count: paywallPrice.installments_count,
    interval_count: paywallPrice.interval_count,
    interval: paywallPrice.interval,
    price_type: paywallPrice.price_type,
    trial_days: paywallPrice.trial_days,
  };
};
