import { useCallback } from "react";
import type { Location } from "history";
import { noop } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";
import type { Post } from "@circle-react/types";
import { useNavigateToPost } from "./useNavigateToPost";
import { useNavigationState } from "./useNavigationState";
import { usePostFetching } from "./usePostFetching";

export interface LocationState {
  prevLocation?: Location;
  postList?: Post[];
  params?: string | Record<string, unknown>;
  hasNextPage?: boolean;
}

export interface NavigationProps {
  initialPostList?: Post[];
  initialPage?: number;
  currentPost: any;
  prevLocation?: Location<LocationState>;
  params?: Record<string, unknown>;
  isNavigatingInsidePostModal?: boolean;
  setIsNavigatingInsidePostModal?: (value: boolean) => void;
  wasInFeed?: boolean;
  isLoading?: boolean;
}

interface Page {
  records: Post[];
  page: number;
  has_next_page: boolean;
  per_page: number;
  count: number;
  page_count: number;
}

type PageParam = number | null;

export interface FetchedData {
  pages: Page[];
  pageParams: PageParam;
}

export const useNavigationLogic = ({
  currentPost,
  prevLocation,
  params,
  isNavigatingInsidePostModal,
  setIsNavigatingInsidePostModal,
  wasInFeed = false,
}: NavigationProps) => {
  const { navigateToPost } = useNavigateToPost();
  const location = useLocation<LocationState>();

  const {
    direction,
    setDirection,
    isPostQueryEnabled,
    setIsPostQueryEnabled,
    hasNextPage,
    setHasNextPage,
  } = useNavigationState(location.state);

  const handleSuccess = useCallback(
    (fetchedData: FetchedData) => {
      if (!isNavigatingInsidePostModal) return;
      const lastPage = fetchedData.pages[fetchedData.pages.length - 1];
      if (lastPage && lastPage.records.length > 0) {
        setIsPostQueryEnabled(false);
        setHasNextPage(lastPage.has_next_page);

        const currentPostIndex = lastPage.records.findIndex(
          (post: Post) => post.id === currentPost.id,
        );

        const nextPostIndex =
          currentPostIndex !== -1 &&
          currentPostIndex < lastPage.records.length - 1
            ? currentPostIndex + 1
            : 0;

        navigateToPost({
          newPost: lastPage.records[nextPostIndex],
          prevLocation,
          params,
        });
      }
      setIsNavigatingInsidePostModal?.(false);
    },
    [
      isNavigatingInsidePostModal,
      navigateToPost,
      setIsNavigatingInsidePostModal,
      setHasNextPage,
      setIsPostQueryEnabled,
      currentPost,
      params,
      prevLocation,
    ],
  );

  const { isLoading, posts } = usePostFetching(
    isPostQueryEnabled,
    wasInFeed,
    handleSuccess,
    params,
  );

  const handleNavigation = useCallback(
    (navDirection: "next" | "previous") => {
      if (isLoading || !posts || !currentPost) return;

      setIsNavigatingInsidePostModal?.(true);
      setDirection(navDirection);

      const currentIndex = posts.findIndex(post => post.id === currentPost.id);
      if (currentIndex === -1) return;

      const newIndex =
        navDirection === "next" ? currentIndex + 1 : currentIndex - 1;

      if (newIndex < 0 || newIndex >= posts.length) {
        if (navDirection === "next") {
          setIsPostQueryEnabled(true);
        } else {
          setIsNavigatingInsidePostModal?.(false);
        }
        return;
      }

      navigateToPost({
        newPost: posts[newIndex],
        prevLocation,
        params,
      });
      setIsNavigatingInsidePostModal?.(false);
    },
    [
      isLoading,
      posts,
      currentPost,
      setDirection,
      navigateToPost,
      setIsPostQueryEnabled,
      prevLocation,
      params,
      setIsNavigatingInsidePostModal,
    ],
  );

  const handlePostNavigation = useCallback(
    (direction: "next" | "previous") => {
      if (!isLoading && !isNavigatingInsidePostModal) {
        handleNavigation(direction);
      }
    },
    [handleNavigation, isLoading, isNavigatingInsidePostModal],
  );

  const isFirstItem = useCallback(() => {
    if (!posts || !currentPost) return false;

    return posts.findIndex(post => post.id === currentPost.id) === 0;
  }, [posts, currentPost]);

  const isLastItemInLastPage = useCallback(() => {
    if (!posts || !currentPost) return false;

    const isLastItem =
      posts.findIndex(post => post.id === currentPost.id) === posts.length - 1;

    return isLastItem && !hasNextPage;
  }, [posts, currentPost, hasNextPage]);

  useHotkeys(
    "right",
    () => {
      if (!isLastItemInLastPage()) {
        handlePostNavigation("next");
      }
    },
    { enabled: !isLoading && !isNavigatingInsidePostModal },
    [handlePostNavigation, isLoading, isNavigatingInsidePostModal],
  );
  useHotkeys(
    "left",
    () => {
      if (!isFirstItem()) {
        handlePostNavigation("previous");
      }
    },
    { enabled: !isLoading && !isNavigatingInsidePostModal },
    [handlePostNavigation, isLoading, isNavigatingInsidePostModal, isFirstItem],
  );

  return {
    isNavigatingToNextPost: direction === "next",
    isNavigatingToPreviousPost: direction === "previous",
    isLoading,
    setIsNavigatingInsidePostModal,
    isNavigatingInsidePostModal,
    shouldDisableLeftNavigation:
      isLoading || isNavigatingInsidePostModal || isFirstItem(),
    shouldDisableRightNavigation:
      isLoading || isNavigatingInsidePostModal || isLastItemInLastPage(),
    handleNextPost: () =>
      isLastItemInLastPage() ? noop : handlePostNavigation("next"),
    handlePreviousPost: () =>
      isFirstItem() ? noop : handlePostNavigation("previous"),
  };
};
