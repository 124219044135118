import { useMemo } from "react";
import { uniqueId } from "lodash";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  DEFAULT_BANNER_BLOCK,
  DEFAULT_BLOCKS,
  DEFAULT_CALL_TO_ACTION_BLOCK,
  DEFAULT_DESCRIPTION_BLOCK,
  DEFAULT_LESSONS_BLOCK,
  DEFAULT_PAYWALLS_BLOCK,
} from "./defaultBlockValues";

const getAvailableBlocksBySpaceTypeMap = ({ isPaywallLockScreenEnabled }) => {
  const newDefaultBlocks = isPaywallLockScreenEnabled
    ? ["banner", "description", "paywalls", "call_to_action"]
    : ["banner", "description", "call_to_action"];

  return {
    event: [...newDefaultBlocks, "legacy_lock_screen"],
    basic: [...newDefaultBlocks, "legacy_lock_screen"],
    chat: [...newDefaultBlocks, "legacy_lock_screen"],
    course: [...newDefaultBlocks, "lessons"],
  };
};

const getDefaultBlockValueMap = ({ isPaywallLockScreenEnabled = false }) => {
  const paywallsBlock = isPaywallLockScreenEnabled
    ? { paywalls: DEFAULT_PAYWALLS_BLOCK }
    : {};

  return {
    banner: DEFAULT_BANNER_BLOCK,
    description: DEFAULT_DESCRIPTION_BLOCK,
    call_to_action: DEFAULT_CALL_TO_ACTION_BLOCK,
    lessons: DEFAULT_LESSONS_BLOCK,
    ...paywallsBlock,
  };
};

/**
 * Each space type has a different set of available blocks. This function is a
 * defensive approach that guarrantees that the space only has the available blocks for its type.
 */
const removeUnavailableBlocks = (
  availableBlocksBySpaceTypeMap = {},
  blocks = [],
  space = {},
) => {
  const { post_type: spaceType = "basic" } = space;

  const availableBlockTypes =
    availableBlocksBySpaceTypeMap[spaceType] ||
    availableBlocksBySpaceTypeMap.basic;

  return blocks.filter(block => availableBlockTypes.includes(block.type));
};

/**
 * There's no functionality to add/remove blocks yet, so we have to guarantee
 * that there's at leaset one of each block for the space. An exception is the
 * legacy lockscreen block, which is only available if the space has legacy
 * lockscreen data.
 */
const addDefaultBlocksIfMissing = (
  availableBlocksBySpaceTypeMap = {},
  defaultBlockValueMap = {},
  blocks = [],
  space = {},
) => {
  const { post_type: spaceType = "basic" } = space;
  let blocksCopy = [...blocks];

  const availableBlockTypes =
    availableBlocksBySpaceTypeMap[spaceType] ||
    availableBlocksBySpaceTypeMap.basic;

  availableBlockTypes.forEach((blockType, index) => {
    if (blockType === "legacy_lock_screen") {
      return;
    }

    if (!blocksCopy.some(block => block.type === blockType)) {
      // This part of the code adds the block following the order provided
      // in the  `getAvailableBlocksBySpaceTypeMap` if the block doesn't exist
      blocksCopy = [
        ...blocksCopy.slice(0, index),
        defaultBlockValueMap[blockType],
        ...blocksCopy.slice(index),
      ];
    }
  });

  return blocksCopy;
};

/**
 * There's no functionality to add/remove blocks yet, so we have to guarantee
 * that there's only one type of each block for the space
 */
const getUniqueBlocksByType = (blocks = []) =>
  blocks.reduce((accumulator, currentBlock) => {
    if (!accumulator.some(block => block.type === currentBlock.type)) {
      accumulator.push(currentBlock);
    }
    return accumulator;
  }, []);

/**
 * The Legacy Lockscreen block is a special case, where it's only available
 * if the space has legacy lockscreen data. If it has, we inject it into the
 * blocks array and make the other blocks hidden. If it already exists in
 * the blocks array, we only add the legacy data to it.
 */
const addLegacyLockscreenData = (blocks = [], space = {}) => {
  const blocksCopy = [...blocks];

  const {
    locked_page_heading = "",
    locked_page_description = {},
    locked_button_label = "",
    locked_button_url = "",
    post_type: spaceType = "basic",
  } = space;

  const { rendered_html_with_layout = "" } = locked_page_description;

  const hasLegacyLockscreenData = Boolean(
    locked_page_heading ||
      locked_button_label ||
      locked_button_url ||
      rendered_html_with_layout,
  );

  if (!hasLegacyLockscreenData || spaceType === "course") {
    return blocksCopy;
  }

  const alreadyHasLegacyBlock = blocksCopy.some(
    block => block.type === "legacy_lock_screen",
  );

  if (alreadyHasLegacyBlock) {
    return blocksCopy.map(block => {
      if (block.type === "legacy_lock_screen") {
        return {
          ...block,
          data: {
            locked_page_heading,
            locked_page_description,
            locked_button_url,
            locked_button_label,
          },
        };
      }
      return block;
    });
  }

  blocksCopy.unshift({
    type: "legacy_lock_screen",
    hidden: false,
    data: {
      locked_page_heading,
      locked_page_description,
      locked_button_url,
      locked_button_label,
    },
  });

  return blocksCopy.map(block => {
    if (block.type === "legacy_lock_screen") {
      return block;
    }
    return {
      ...block,
      hidden: true,
    };
  });
};

/**
 * This ID generation is a front-end only optimization, so this value can be
 * used on "key" props, and to be able to refer back to each block by its ID
 */
const addUniqueIdsToBlocks = blocks =>
  blocks.map(block => ({
    ...block,
    id: uniqueId("block_"),
  }));

/**
 * The lessons block does not have editable data fields, but we still inject
 * the lessons into the block so that it can render this information on the preview
 */
const addLessonsData = (blocks = [], space = {}) => {
  const { course_sections = [] } = space;
  const sections = Array.isArray(course_sections) ? course_sections : [];

  return blocks.map(block => {
    if (block.type !== "lessons") {
      return block;
    }
    return {
      ...block,
      data: {
        ...block.data,
        sections,
      },
    };
  });
};

const getLockscreenBlocks = ({
  space = {},
  isPaywallLockScreenEnabled = false,
}) => {
  const { lock_screen_blocks: spaceBlocks = [] } = space;
  const hasBlocks = Array.isArray(spaceBlocks) && spaceBlocks.length > 0;
  const blocks = hasBlocks ? spaceBlocks : [];

  const availableBlocksBySpaceTypeMap = getAvailableBlocksBySpaceTypeMap({
    isPaywallLockScreenEnabled,
  });

  const filteredBlocks = removeUnavailableBlocks(
    availableBlocksBySpaceTypeMap,
    blocks,
    space,
  );
  const uniqueBlocks = getUniqueBlocksByType(filteredBlocks);
  const blocksWithLegacy = addLegacyLockscreenData(uniqueBlocks, space);
  const blocksWithIds = addUniqueIdsToBlocks(blocksWithLegacy);

  return addLessonsData(blocksWithIds, space);
};

const getDefaultLockscreenBlocks = ({
  space = {},
  isPaywallLockScreenEnabled,
}) => {
  const { lock_screen_blocks: spaceBlocks = [] } = space;
  const hasBlocks = Array.isArray(spaceBlocks) && spaceBlocks.length > 0;
  const blocks = hasBlocks ? spaceBlocks : DEFAULT_BLOCKS;

  const availableBlocksBySpaceTypeMap = getAvailableBlocksBySpaceTypeMap({
    isPaywallLockScreenEnabled,
  });
  const defaultBlockValueMap = getDefaultBlockValueMap({
    isPaywallLockScreenEnabled,
  });

  const filteredBlocks = removeUnavailableBlocks(
    availableBlocksBySpaceTypeMap,
    blocks,
    space,
  );
  const blocksBySpaceType = addDefaultBlocksIfMissing(
    availableBlocksBySpaceTypeMap,
    defaultBlockValueMap,
    filteredBlocks,
    space,
  );
  const uniqueBlocks = getUniqueBlocksByType(blocksBySpaceType);
  const blocksWithLegacy = addLegacyLockscreenData(uniqueBlocks, space);
  const blocksWithIds = addUniqueIdsToBlocks(blocksWithLegacy);

  return addLessonsData(blocksWithIds, space);
};

export const useGetLockscreenBlocks = (space = {}) => {
  const { currentCommunitySettings } = usePunditUserContext();

  return useMemo(
    () =>
      getLockscreenBlocks({
        space,
        isPaywallLockScreenEnabled:
          currentCommunitySettings?.paywall_lock_screen_enabled,
      }),
    [space, currentCommunitySettings?.paywall_lock_screen_enabled],
  );
};

export const useGetDefaultLockscreenBlocks = (space = {}) => {
  const { currentCommunitySettings } = usePunditUserContext();

  return useMemo(
    () =>
      getDefaultLockscreenBlocks({
        space,
        isPaywallLockScreenEnabled:
          currentCommunitySettings?.paywall_lock_screen_enabled,
      }),
    [space, currentCommunitySettings?.paywall_lock_screen_enabled],
  );
};
