export const settingsNavPathV3 = {
  content: {
    posts: "/settings/posts",
    topics: "/settings/topics",
    moderation: "/settings/moderation",
    posts_bulk_actions: "/settings/posts/bulk_actions",
    moderation_edit: "/settings/moderation/edit",
    live_streams: "/settings/live_streams",
    edit_moderation: "/settings/moderation/edit",
  },
  email: {
    broadcast: "/settings/emails/broadcast",
    new_broadcast: "/settings/emails/broadcast/new",
    settings: "/settings/emails/settings",
  },
  members: {
    manage: "/audience/manage",
    invite: "/members/invite/details",
    invite_access: "/members/invite/access",
    invite_emails: "/members/invite/emails",
    invite_paywalls: "/members/invite/paywalls",
    bulk_import: "/members/bulk_import",
    bulk_logs: "/settings/members/bulk_import_tasks",
    onboarding: "/members/onboarding",
    onboarding_access: "/members/onboarding/access",
    onboarding_workflows: "/members/onboarding/workflows",
    gamification: "/settings/gamification",
    invitation_links: "/members/invitation_links",
    new_invitation_link: "/members/invitation_links/new",
    edit_invitation_link: "/members/invitation_links/:id/edit",
    member_tags: "/settings/member_tags",
    new_member_tags: "/settings/member_tags/new",
    edit_member_tags: "/settings/member_tags/:id/edit",
    profile_fields: "/members/profile_fields",
    activity_logs: "/settings/members/activity_logs",
    access_groups: "/settings/access_groups",
  },
  workflows: {
    all: "/settings/workflows",
    history: "/settings/workflows/history",
  },
  paywalls: {
    index: "/settings/paywalls",
    coupons: "/settings/coupons",
    subscription_groups: "/settings/paywall_groups",
    transactions: "/settings/paywall_charges",
    subscriptions: "/settings/paywall_subscriptions",
    taxes: "/settings/paywall_tax_settings",
    bulk_logs: "/settings/paywall_bulk_logs",
    payment_settings: "/settings/paywall_payment_settings",
  },
  affiliates: {
    directory: "/settings/affiliates_directory",
    commissions: "/settings/affiliates_commissions",
    settings: "/settings/affiliates_settings",
  },
  plans: {
    general: "/settings/plans",
    billing: "/settings/billing",
    brandedApps: "/settings/branded_apps",
  },
  developers: {
    overview: "/settings/developers",
    api: "/settings/api",
    new_api: "/settings/api/new",
  },
  live_streams: {
    live_streams: "/settings/live_streams",
  },
  settings: {
    general: "/settings",
    onboarding: "/settings/onboarding",
    community_ai: "/settings/community_ai",
    custom_domain: "/settings/custom_domain",
    weekly_digest: "/settings/weekly_digest",
    embed: "/settings/embed",
    single_sign_on: "/settings/sso",
    single_sign_on_verify_connection: "/custom_logins/debug_sessions",
    messaging: "/settings/messaging",
    legal: "/settings/legal",
    navigation: "/settings/navigation",
  },
  analytics_v2: {
    index: "/settings/analytics",
    members: "/settings/analytics/members",
    post_comments: "/settings/analytics/post_comments",
    messages: "/settings/analytics/messages",
    spaces: "/settings/analytics/spaces",
    events: "/settings/analytics/events",
    devices: "/settings/analytics/devices",
    payments: "/settings/analytics/payments",
  },
  site: {
    index: "/settings/home",
    navigation: "/settings/navigation",
    spaces: "/settings/spaces",
    mobile_app: "/settings/mobile_app",
    code_snippets: "/settings/code_snippets",
  },
} as const;
