import { useEffect } from "react";
import { usePostList as useHomePagePostList } from "@circle-react/components/HomePageV2/usePostList";
import { usePostList } from "@circle-react/hooks/posts/usePostList";
import type { Post } from "@circle-react/types";
import type { FetchedData } from "./useNavigationLogic";

export const usePostFetching = (
  isPostQueryEnabled: boolean,
  wasInFeed: boolean,
  onSuccess: (fetchedData: FetchedData) => void,
  params?: string | Record<string, unknown>,
): {
  posts: Post[];
  isLoading: boolean;
} => {
  const homePageSort = typeof params === "string" ? params : "";

  const {
    posts: spacePosts,
    isLoading: isLoadingSpace,
    fetchNextPage: fetchNextPageInSpace,
  } = usePostList(params, isPostQueryEnabled && !wasInFeed, onSuccess);

  const {
    posts: homePagePosts,
    isLoading: isLoadingHome,
    fetchNextPage,
  } = useHomePagePostList(
    homePageSort,
    isPostQueryEnabled && wasInFeed,
    onSuccess,
  );

  useEffect(() => {
    if (isPostQueryEnabled) {
      if (wasInFeed) {
        void fetchNextPage();
      } else {
        void fetchNextPageInSpace();
      }
    }
  }, [isPostQueryEnabled, wasInFeed, fetchNextPage, fetchNextPageInSpace]);

  return {
    posts: wasInFeed ? homePagePosts : spacePosts,
    isLoading: wasInFeed ? isLoadingHome : isLoadingSpace,
  };
};
