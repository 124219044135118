import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { SettingsApp } from "@circle-react/apps/SettingsApp";
import { OptInLandingPage } from "@circle-react/components/NewVersionOptIn/OptInLandingPage";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { AccountPage } from "@circle-react/pages/Account";
import { BookmarksPage } from "@circle-react/pages/Bookmarks";
import { ImportContactsPage } from "@circle-react/pages/Contacts/Import";
import { GettingStartedPage } from "@circle-react/pages/GettingStarted";
import { LiveMobilePage } from "@circle-react/pages/Live/Mobile";
import { LiveNotSupportedPage } from "@circle-react/pages/Live/NotSupported";
import { LivePublicPage } from "@circle-react/pages/Live/Public";
import { LiveRecordPage } from "@circle-react/pages/Live/Record";
import { LiveRoomPage } from "@circle-react/pages/Live/Room";
import { MarketingEmailsPreferencesPage } from "@circle-react/pages/MarketingEmails/EmailPreferences";
import { MarketingEmailsUnsubscribePage } from "@circle-react/pages/MarketingEmails/Unsubscribe";
import { MessagesPage } from "@circle-react/pages/Messages";
import { NotificationsPage } from "@circle-react/pages/Notifications";
import { UnsubscribePage } from "@circle-react/pages/Public/Unsubscribe";
import { ThreadsPage } from "@circle-react/pages/Threads";
import { TwoFactorAuthenticationPage } from "@circle-react/pages/TwoFactorAuthentication";
import { AdminMemberEditRoutes } from "@circle-react/routes/Main/AdminMemberEditRoutes";
import { RootRoute } from "@circle-react/routes/Main/RootRoute";
import { ProtectedRoute } from "@circle-react-shared/ProtectedRoute";
import { ErrorBoundary } from "@circle-react-uikit/ErrorBoundary";
import { SomethingWentWrong } from "@circle-react-uikit/ErrorPages";

const renderSomethingWentWrong = () => <SomethingWentWrong />;
const settingsMembersRoutes: any = Object.values(settingsNavPath.members);

export const Routes = () => {
  const currentLocation = useLocation<any>();
  const { prevLocation } = currentLocation?.state ?? {};
  const location = prevLocation ?? currentLocation;

  return (
    <ErrorBoundary renderFunc={renderSomethingWentWrong}>
      <Switch location={location}>
        <ProtectedRoute
          component={LiveNotSupportedPage}
          path="/live/:roomSlug/not_supported"
        />
        <ProtectedRoute
          component={LiveRecordPage}
          path="/live/:roomSlug/record"
        />
        <ProtectedRoute
          component={LiveMobilePage}
          path="/live/:roomSlug/mobile"
        />
        <Route component={LivePublicPage} path="/live/:roomSlug/public" />
        <ProtectedRoute component={LiveRoomPage} path="/live/:roomSlug" />
        <Route component={UnsubscribePage} path="/public/unsubscribe" />
        <Route component={TwoFactorAuthenticationPage} path="/two_fa" />
        <Route
          component={MarketingEmailsUnsubscribePage}
          path="/marketing_emails/unsubscribe"
        />
        <Route
          component={MarketingEmailsPreferencesPage}
          path="/marketing_emails/marketing_email_preference"
        />
        <ProtectedRoute
          component={GettingStartedPage}
          path="/getting-started"
          exact
        />
        <ProtectedRoute
          component={OptInLandingPage}
          path="/features/visual-refresh"
        />
        <ProtectedRoute
          component={MessagesPage}
          path={["/messages/:uuid", "/messages"]}
        />
        <ProtectedRoute component={ThreadsPage} path="/threads" />
        <ProtectedRoute component={NotificationsPage} path="/notifications" />
        <ProtectedRoute component={BookmarksPage} path="/bookmarks" />
        <ProtectedRoute
          component={SettingsApp}
          path={["/settings", ...settingsMembersRoutes]}
        />
        <ProtectedRoute
          component={ImportContactsPage}
          path="/contacts/import"
        />
        <Redirect
          from="/members/manage"
          to={{ pathname: "/audience/manage", search: currentLocation.search }}
        />
        <ProtectedRoute component={AccountPage} path="/account" />
        <ProtectedRoute
          component={AdminMemberEditRoutes}
          path="/admin/members"
        />
        <RootRoute />
      </Switch>
    </ErrorBoundary>
  );
};
