/* eslint-disable no-restricted-syntax -- Not translatable strings */
import { t } from "i18n-js";

export const BROADCAST_STATUS = {
  ALL: "all",
  DRAFT: "draft",
  SENT: "sent",
  PROCESSING: "processing",
  SCHEDULED: "scheduled",
  FAILED: "failed",
} as const;

export const BROADCAST_TYPES = {
  standard: "standard",
  workflow: "workflow",
} as const;

export type BroadcastStatusValue =
  (typeof BROADCAST_STATUS)[keyof typeof BROADCAST_STATUS];

export const BROADCAST_TABS: ReadonlyArray<BroadcastStatusValue> = [
  BROADCAST_STATUS.ALL,
  BROADCAST_STATUS.SENT,
  BROADCAST_STATUS.DRAFT,
  BROADCAST_STATUS.SCHEDULED,
];

export const STATUS_VARIANT_MAP: any = {
  [BROADCAST_STATUS.DRAFT]: "default",
  [BROADCAST_STATUS.SENT]: "success",
  [BROADCAST_STATUS.PROCESSING]: "info",
  [BROADCAST_STATUS.SCHEDULED]: "warning",
  [BROADCAST_STATUS.FAILED]: "danger",
};

export const TAB_QUERY_NAME = "status";
export const statusForViewModal: ReadonlyArray<BroadcastStatusValue> = [
  BROADCAST_STATUS.PROCESSING,
  BROADCAST_STATUS.SENT,
];

export const REFETCH_INTERVAL = 15000;

const memberGroupLabel = t("dynamic_vars_groups.member");
const communityGroupLabel = t("dynamic_vars_groups.community");

export const BROADCAST_PERSONALIZATION_VARIABLES = [
  {
    group: memberGroupLabel,
    label: "{{member.first_name}}",
    value: "{{member.first_name}}",
  },
  {
    group: memberGroupLabel,
    label: "{{member.last_name}}",
    value: "{{member.last_name}}",
  },
  {
    group: memberGroupLabel,
    label: "{{member.name}}",
    value: "{{member.name}}",
  },
  {
    group: memberGroupLabel,
    label: "{{member.email}}",
    value: "{{member.email}}",
  },
  {
    group: memberGroupLabel,
    label: "{{member.public_uid}}",
    value: "{{member.public_uid}}",
  },
  {
    group: communityGroupLabel,
    label: "{{community.name}}",
    value: "{{community.name}}",
  },
  {
    group: communityGroupLabel,
    label: "{{community.url}}",
    value: "{{community.url}}",
  },
];
