import { isCard } from "@circle-react/helpers/paywallCheckoutHelpers";
import { usePunditUserContext } from "../punditUserContext";

export const useCardInfo = (
  paywall: any,
  selectedPrice: any,
  payment_method_type: any,
  isCardInfoComplete: boolean,
) => {
  const { currentCommunitySettings: { paywalls_3ds_auth_enabled } = {} } =
    usePunditUserContext();

  const isCardInfoRequired =
    (!selectedPrice.trial_enabled ||
      selectedPrice.trial_requires_upfront_payment ||
      paywall.trial_requires_card) &&
    isCard(payment_method_type);
  const isCardInfoValid = !isCardInfoRequired || isCardInfoComplete;

  const isTrialThatRequiresCard =
    isCard(payment_method_type) &&
    paywall.trial_requires_card &&
    selectedPrice.trial_enabled &&
    !selectedPrice.trial_requires_upfront_payment;

  const isSetupIntentRequired =
    isTrialThatRequiresCard || !paywalls_3ds_auth_enabled;

  const arePostCreateActionsRequired = paywalls_3ds_auth_enabled;

  return {
    isCardInfoRequired,
    isCardInfoValid,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
  };
};
